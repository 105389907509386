export default {
  colors: {
    background: '#fff',
    text: '#3b3b3b',
    primary: '#e7388b',
    secondary: '#e7388b',
    tertiary: '#f3f3f3',
    highlight: '#e7388b',
  },
  fonts: {
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '560px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}
